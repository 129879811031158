// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  Firestore,
} from "firebase/firestore/lite";
import {
  ApparitionApprovalStatus,
  MarianApparitionData,
} from "../constants/MarianApparitionData";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjco8ejRbK_GYNYlFM5tXf3lO4Iu9pp4E",
  authDomain: "marian-f8ecf.firebaseapp.com",
  projectId: "marian-f8ecf",
  storageBucket: "marian-f8ecf.appspot.com",
  messagingSenderId: "650396615343",
  appId: "1:650396615343:web:1d63cacecabe17bad40a5a",
  measurementId: "G-YTB3H34YBE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// dev token to pass appcheck recaptcha when using localhost
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdPPRUqAAAAACzEpgscbXGIyAXXBYke2RxdDJgR"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

const db: Firestore = getFirestore(app);

// Get a list of Marian Apparitions from firestore database
export const getMarianApparitionsData = async () => {
  try {
    const apparitionsCol = collection(db, "MarianApparitions");
    const apparitionsSnapshot = await getDocs(apparitionsCol);
    const apparitionsList = apparitionsSnapshot.docs.map((doc) => doc.data());

    // format data
    const formattedData: MarianApparitionData[] = apparitionsList
      ?.map((document) => {
        return {
          Name: document.Name ?? "",
          Location: document.Location ?? "",
          StartDate: document.StartDate?.toDate(),
          Description: document.Description
            ? {
                Text: document.Description?.Text ?? "",
                Citation: document.Description?.Citation,
                CitationLink: document.Description?.CitationLink,
              }
            : undefined,
          Image: document.Image
            ? {
                Link: document.Image?.Link ?? "",
                Citation: document.Image?.Citation,
                CitationLink: document.Image?.CitationLink,
              }
            : undefined,
          ApprovalStatus:
            document.ApprovalStatus ?? ApparitionApprovalStatus.Unapproved,
          Seers: document.Seers ?? [],
          NumApparitions: document.NumApparitions,
        };
      })
      .filter((item) => item.StartDate); // must have a StartDate

    return formattedData;
  } catch (err) {
    console.log("failed getting apparition data:", err);
    return [];
  }
};
