export interface MarianApparitionData {
  Name: string;
  Location: string;
  StartDate: Date;
  Description?: ApparitionDescription;
  Image?: ApparitionImage;
  ApprovalStatus: ApparitionApprovalStatusType;
  Seers?: string[];
  NumApparitions?: number;
}

export interface ApparitionDescription {
  Text: string;
  Citation?: string;
  CitationLink?: string;
}

export interface ApparitionImage {
  Link: string;
  CitationLink?: string;
  Citation?: string;
}

export const ApparitionApprovalStatus: {
  VaticanApproved: "VaticanApproved";
  CopticApproved: "CopticApproved";
  Unapproved: "Unapproved";
  BishopApproved: "BishopApproved";
} = {
  VaticanApproved: "VaticanApproved",
  CopticApproved: "CopticApproved",
  Unapproved: "Unapproved",
  BishopApproved: "BishopApproved",
};

export type ApparitionApprovalStatusType =
  (typeof ApparitionApprovalStatus)[keyof typeof ApparitionApprovalStatus];
