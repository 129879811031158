import { createContext, useEffect, useState } from "react";
import { getMarianApparitionsData } from "../data/firebase";
import { MarianApparitionData } from "../constants/MarianApparitionData";
import TopNav from "../components/TopNav/TopNav";
import { Outlet } from "react-router-dom";

export const ApparitionDataContext = createContext<
  MarianApparitionData[] | undefined
>(undefined);

const Root = () => {
  const [apparitionData, setApparitionData] = useState<
    MarianApparitionData[] | undefined
  >();

  // fetch apparition data from firebase
  useEffect(() => {
    const getData = async () => {
      const data = await getMarianApparitionsData();
      setApparitionData(data);
    };

    getData();
  }, []);

  return (
    <ApparitionDataContext.Provider value={apparitionData}>
      <TopNav />
      <Outlet />
    </ApparitionDataContext.Provider>
  );
};

export default Root;
