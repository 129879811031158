import { useContext } from "react";
import TimelineView from "../components/TimelineView";
import { ApparitionDataContext } from "./Root";

const TimelinePage = () => {
  const apparitionData = useContext(ApparitionDataContext);

  return (
    <TimelineView
      apparitionData={apparitionData?.sort(
        (a, b) => b.StartDate.getTime() - a.StartDate.getTime()
      )}
    />
  );
};

export default TimelinePage;
