import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import Typography from "@mui/material/Typography";
import { MarianApparitionData } from "../constants/MarianApparitionData";
import { format } from "date-fns";
import { Box, styled, useTheme } from "@mui/material";
//import Citation from "./Citation";

interface TimelineViewProps {
  apparitionData: MarianApparitionData[] | undefined;
}

// See timeline docs @ https://mui.com/material-ui/react-timeline/
const TimelineView = ({ apparitionData }: TimelineViewProps) => {
  const theme = useTheme();

  if (!apparitionData || apparitionData.length === 0) return null;

  //console.log(apparitionData);

  return (
    <Timeline
      position="right"
      sx={{
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          padding: "6px 0px",
        },
        wordBreak: "break-word",
      }}
    >
      {apparitionData.map((item, idx) => {
        return (
          <TimelineItem
            sx={{ width: "100%", maxWidth: "1200px", minHeight: "160px" }}
            key={`${idx}-${item.Name}`}
          >
            <TimelineOppositeContent
              marginY="26px"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  paddingX: "0px",
                },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="end"
                flexDirection="column"
                height="100%"
              >
                <Box>
                  <Typography
                    variant="h4"
                    color="text.primary"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: theme.typography.h5,
                      },
                    }}
                  >
                    {format(item.StartDate, "yyyy")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: theme.typography.body2,
                      },
                    }}
                  >
                    {item.Location}
                  </Typography>
                </Box>
              </Box>
            </TimelineOppositeContent>
            <TimelineSeparator sx={{ padding: "0 12px" }}>
              <TimelineConnector />
              <Box width="100px" display="flex" justifyContent="center">
                {item.Image && item.Image.Link ? (
                  <TimelineDot
                    sx={{
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <StyledImg theme={theme} src={item.Image.Link} alt="" />
                  </TimelineDot>
                ) : (
                  <TimelineDot>
                    <BlurOnIcon />
                  </TimelineDot>
                )}
              </Box>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: "12px",
                px: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column-reverse",
                  alignItems: "baseline",
                  justifyContent: "center",
                },
                [theme.breakpoints.down("sm")]: {
                  paddingLeft: "0px",
                  paddingRight: "8px",
                },
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      fontSize: theme.typography.body1,
                      fontWeight: "bold",
                    },
                  }}
                >
                  {item.Name}
                </Typography>
                {/* <Typography
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      fontSize: theme.typography.body2,
                    },
                  }}
                >
                  {item.Description?.Text}
                </Typography>
                {item.Description?.Citation && (
                  <Citation
                    text={item.Description.Citation}
                    link={item.Description.CitationLink}
                  />
                )} */}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default TimelineView;

const StyledImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxHeight: "70px",
    maxWidth: "70px",
  },
  maxHeight: "100px",
  maxWidth: "100px",
  borderRadius: "10px",
}));
