import { useEffect, useState } from "react";

const BackendTest = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the backend
    fetch("/api/data")
      .then((response) => response.json())
      .then((data) => {
        console.log("data returned", data);
        setData(data);
      });
  }, []);
  return (
    <>
      <span>Data from the Backend:</span>
      <ul>
        {data.map((item, index) => (
          <li key={index}>{JSON.stringify(item)}</li>
        ))}
      </ul>
    </>
  );
};

export default BackendTest;
